import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import settings from './settings';
import tableViewReducer from './tableViewReducer';
import filterReducer from './filterReducer';

const reducers = {
  dataTable: tableViewReducer,
  filters: filterReducer,
  routing: routerReducer,
  settings
};

export default combineReducers(reducers);
