export const TOGGLE_BOXED_LAYOUT = 'TOGGLE_BOXED_LAYOUT';
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const TOGGLE_NAV_BEHIND = 'TOGGLE_NAV_BEHIND';
export const TOGGLE_FIXED_HEADER = 'TOGGLE_FIXED_HEADER';
export const CHANGE_SIDEBAR_WIDTH = 'CHANGE_SIDEBAR_WIDTH';
export const CHANGE_COLOR_OPTION = 'CHANGE_COLOR_OPTION';
export const CHANGE_THEME = 'CHANGE_THEME';


export const FETCH_POST = "FETCH_POST";
export const SEND_POST = "SEND_POST";

export const GET_FILTER_DATA = "GET_FILTER_DATA";
export const SEND_FILTER_DATA = "SEND_FILTER_DATA";
