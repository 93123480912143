import * as types from "../constants/actionTypes";

const initialState = {};

export default function(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_FILTER_DATA:
      return {
        ...state,
        ...actions.payload
      };
    case types.SEND_FILTER_DATA:
    default:
      return state;
  }
}
