// export const API_RESULTSET_URL = "http://apigateway.relevancelab.com/prismmicro-resultset";
export const API_RESULTSET_URL = "https://agt-swagger.relevancelab.com/prismmicro-resultset";


// export const API_DASHBOARD_URL = "http://apigateway.relevancelab.com/prismmicro-dashboard";
export const API_DASHBOARD_URL = "https://agt-swagger.relevancelab.com/prismmicro-dashboard";

// export const API_STUDY_INFORMATION_URL = "http://apigateway.relevancelab.com/agt-services";
export const API_STUDY_INFORMATION_URL = "https://agt-swagger.relevancelab.com/agt-services";
// export const API_STUDY_INFORMATION_URL = "http://localhost:8092";

// export const API_STUDY_INFORMATION_REPORT_URL = "http://apigateway.relevancelab.com/agt-services/downloadScenarioPdf";
export const API_STUDY_INFORMATION_REPORT_URL = "https://agt-swagger.relevancelab.com/agt-services/downloadScenarioPdf";

export const API_AUTOCOMPLETE_URL = "https://agt-swagger.relevancelab.com/v1.0";

// export const API_AUTHENTICATION_URL = "http://apigateway.relevancelab.com/prismmicro-authentication";
export const API_AUTHENTICATION_URL = "https://agt-swagger.relevancelab.com/prismmicro-authentication";

// export const API_RESULTSET_URL = "http://apigateway.mareana.com/prismmicro-resultset";
// export const API_DASHBOARD_URL = "http://apigateway.mareana.com/prismmicro-dashboard";
// export const API_STUDY_INFORMATION_URL = "http://apigateway.mareana.com/agt-services";
// export const API_STUDY_INFORMATION_REPORT_URL = "http://apigateway.relevancelab.com/agt-services/downloadScenarioPdf";
// export const API_AUTOCOMPLETE_URL = "http://swagger.mareana.com/v1.0";
// export const API_AUTHENTICATION_URL = "http://apigateway.mareana.com/prismmicro-authentication";
