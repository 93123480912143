import * as types from '../constants/actionTypes';
import { dataTableConfig } from '../constants/datatableConfig';

const initialState = { ...dataTableConfig };
export default function(state = initialState, actions) {
  switch(actions.type) {
    case types.FETCH_POST:
      return {
        ...state,
        header: {
          ...state.header,
          config: [...actions.payload.config]
        }, 
        body: {
          ...state.body,
          ...actions.payload.data
        },
        filters: [...actions.payload.filters || []]
      }
    case types.SEND_POST:
      return {
        ...actions.payload
      }
    default: 
      return state;
  }
}