import {API_RESULTSET_URL} from '../constants/apiBaseUrl'; 
export const dataTableConfig = {
  templateType: "primary",
  enableRowSelect: false,
  showNoOfColumns: 10,
  enableCheckBoxSelection: false,
  navigateButton: {
    show: false,
    header: "",
    icon: '',
    color: ''
  },
  toolBar: {
    show: false,
    title: {
      show: false,
      name: "Entitlement",
      style: {}
    },
    search: {
      show: false,
      highlightKeyword: true,
      label: "Search",
      width: "120%",
      style: {
        marginLeft: 0
      }
    },
    columnVisibility: {
      show: true,
      style: {}
    },
    filter: {
      show: true,
      style: {}
    },
    // customButtons: [
    //   { name: "Compare Plan", icon: "playlist_add", position: "left" }
    // ],
    style: {}
  },
  header: {
    show: true,
    config: [],
    align: "left",
    field: "fieldName",
    /* sort: true,
    columnFilter: true,
    style: {}*/
    // moreOptions: {
    //   header: "Actions",
    //   menuItems: [
    //     "Add",
    //     "Remove",
    //     "Clone"
    //   ]
    // },
    width: 150,
    columnsStyle: [{
      "Material Name": {
        style: {
          width: "150px"
        }
      }
    }],
    columnFilterMenus: [
      { "key": "contain", "value": "Contains" },
      { "key": "start with", "value": "Starts With" },
      { "key": "ends with", "value": "Ends With" },
      { "key": "matches", "value": "Matches" },
      { "key": "less than", "value": "Less Than" }
    ],
    order: 'asc',
    orderBy: 'material'
  },
  body: {
    align: "left",
    content: [],
    // cellClicks: [
    //   { cellName: 'Material Name', enableHyperLink: true, url: "http://...." },
    //   { cellName: 'Material' }
    // ],
    emptyRows: {
      message: 'No Record Found',
      showEmptyRows: true,
      style: {
        fontWeight: 'bolder',
        textAlign: 'center',
        padding: '20px'
      }
    },
    filteredRecord: 100,
    pageNumber: 0,
    pageSize: 25,
    totalRecord: 100,
    style: {}
  },
  /* footer: {
  show: true,
  paginations: {
  show: true,
  rowsPerPageOptions: [10, 25, 50],
  colSpan: 6,
  style: {}
  },
  style: {}
  }, */
  style: {},
  mode: {
    "apiConfig": {
      "apiLink": API_RESULTSET_URL+"/returnData",
      "params": {
        "resultsetId": "resultsetId",
        "appId": "appId",
        "search": "search",
        "pageNumber": "pageNumber",
        "sortColumn": "sortColumn",
        "sortOrder": "sortOrder",
        "pageSize": "pageSize",
        "pageOffset": "pageOffset",
        "filters": "filters"
      }
    },
    requiredInfo: {
      "resultsetId": 4,
      "appId": "ENT"
    }
  }
};

//dataTableConfig.mode.apiConfig.apiLink = ;

